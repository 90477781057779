import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  Button,
} from "@mui/material";
import { theme } from "./theme"; // We'll move the theme to a separate file
import logo from "./logo.png";

const Section = ({ title, children }) => (
  <Box sx={{ mb: 4 }}>
    <Typography
      variant="h5"
      component="h2"
      gutterBottom
      sx={{ color: "primary.main" }}
    >
      {title}
    </Typography>
    {children}
  </Box>
);

const PrivacyPolicy = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: "primary.main", fontWeight: "bold" }}
            >
              Goodnewzz
            </Typography>
            <img
              src={logo}
              alt="Goodnewzz Logo"
              style={{ height: "32px", marginLeft: "8px" }}
            />
          </Box>
          <Button color="inherit" href="/">
            Back to Home
          </Button>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          Privacy Policy
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          paragraph
          align="center"
        >
          Last updated: December 8, 2024
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Section title="Introduction">
            <Typography paragraph>
              This privacy policy applies to the Goodnewzz app (hereby referred
              to as "Application") for mobile devices that was created by
              Shubham Choudhary (hereby referred to as "Service Provider") as a
              Freemium service. This service is intended for use "AS IS".
            </Typography>
          </Section>

          <Section title="Information Collection and Use">
            <Typography paragraph>
              The Application collects information when you download and use it.
              This information may include:
            </Typography>
            <Typography component="ul" sx={{ pl: 4 }}>
              <li>Your device's Internet Protocol address (e.g. IP address)</li>
              <li>
                The pages of the Application that you visit, the time and date
                of your visit
              </li>
              <li>The time spent on those pages</li>
              <li>The time spent on the Application</li>
              <li>The operating system you use on your mobile device</li>
            </Typography>
            <Typography paragraph sx={{ mt: 2 }}>
              The Application does not gather precise information about the
              location of your mobile device.
            </Typography>
          </Section>

          {/* Add remaining sections following the same pattern */}
          <Section title="Third Party Access">
            <Typography paragraph>
              Only aggregated, anonymized data is periodically transmitted to
              external services to aid the Service Provider in improving the
              Application and their service. The Service Provider may share your
              information with third parties in the ways that are described in
              this privacy statement.
            </Typography>
          </Section>

          <Section title="Children's Privacy">
            <Typography paragraph>
              The Service Provider does not use the Application to knowingly
              solicit data from or market to children under the age of 13. The
              Application does not address anyone under the age of 13.
            </Typography>
          </Section>

          <Section title="Contact Us">
            <Typography paragraph>
              If you have any questions regarding privacy while using the
              Application, or have questions about our practices, please contact
              the Service Provider via email at:
            </Typography>
            <Typography
              component="a"
              href="mailto:deol.shubham@gmail.com"
              sx={{
                color: "primary.main",
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              deol.shubham@gmail.com
            </Typography>
          </Section>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default PrivacyPolicy;
