import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Heart, Droplets, Music } from "lucide-react";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import EmailIcon from "@mui/icons-material/Email";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import logo from "./logo.png";
import { theme } from "./theme";

// Define your theme

const IconSvg = ({ icon: Icon }) => {
  return <Icon size={48} style={{ color: theme.palette.primary.main }} />;
};

const features = [
  {
    title: "Pregnancy Exercises",
    icon: (props) => (
      <SelfImprovementIcon {...props} sx={{ fontSize: 64, p: 1 }} />
    ),
    description:
      "Safe and effective pregnancy exercises with 3D animated demonstrations to improve flexibility, strengthen muscles for labor, and maintain overall wellness during pregnancy",
  },
  {
    title: "AI Companion for Pregnancy Support",
    icon: Heart,
    description:
      "Your personal AI companion to help understand medical terms, plan pregnancy-safe diets, and get answers to all your pregnancy-related questions",
  },
  {
    title: "Daily water intake tracker",
    icon: Droplets,
    description:
      "Get reminders on how much water you should be drinking and set daily water intake goals",
  },
  {
    title: "Calm and peaceful music for good night sleep",
    icon: Music,
    description: "Manage your sleep by listening to calm and peaceful music",
  },
];

const FeatureCard = ({ title, icon, description }) => (
  <Card
    elevation={2}
    sx={{ height: "100%", display: "flex", flexDirection: "column" }}
  >
    <CardContent
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <IconSvg icon={icon} loading="lazy" />
      <Typography variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const MobileMenu = ({ open, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <List sx={{ width: 250 }}>
        <ListItem button component="a" href="#features" onClick={onClose}>
          <ListItemText primary="Features" />
        </ListItem>
        <ListItem button component="a" href="#waitlist" onClick={onClose}>
          <ListItemText primary="Join Waitlist" />
        </ListItem>
        <ListItem button component="a" href="#contact" onClick={onClose}>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component="a" href="/privacy-policy" onClick={onClose}>
          <ListItemText primary="Privacy Policy" />
        </ListItem>
      </List>
    </Drawer>
  );
};

const LandingPage = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleJoinWaitlist = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLScjpoYmQvDC9mdZgi_obFkGD7wd_rqHu0A3YXDV8Bs1wEt-xQ/viewform?usp=sf_link",
      "_blank"
    );
  };

  return (
    <>
      <CssBaseline />
      <header>
        <AppBar
          position="fixed"
          color="transparent"
          elevation={0}
          sx={{
            backdropFilter: "blur(2px)",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            borderBottom: "1px solid",
            borderColor: "divider",
            // zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                Goodnewzz
              </Typography>
              <Box
                component="img"
                src={logo}
                alt="Goodnewzz Logo"
                sx={{
                  height: 32,
                  width: 32,
                  marginLeft: 1,
                  objectFit: "contain",
                }}
                loading="lazy"
              />
            </Box>
            {isMobile ? (
              <IconButton
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <>
                <Button color="inherit" href="#features">
                  Features
                </Button>
                <Button color="inherit" href="#waitlist">
                  Join Waitlist
                </Button>
                <Button color="inherit" href="#contact">
                  Contact
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        <MobileMenu
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
        />
      </header>

      <main>
        <article>
          <section aria-label="Hero Section">
            <Container maxWidth="md" sx={{ mt: 8, mb: 8, textAlign: "center" }}>
              <Typography variant="h2" component="h1" gutterBottom>
                Your Ultimate Pregnancy Companion
              </Typography>
              <Typography
                variant="h5"
                component="p"
                color="text.secondary"
                paragraph
              >
                Empowering expectant mothers with AI-powered insights and
                personalized care
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<EmailIcon />}
                onClick={handleJoinWaitlist}
                sx={{ mt: 2 }}
              >
                Join the Waitlist
              </Button>
            </Container>

            <Container id="features" maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
              <Typography
                variant="h3"
                component="h2"
                align="center"
                gutterBottom
              >
                Features
              </Typography>
              <Grid container spacing={4}>
                {features.map((feature, index) => (
                  <Grid item key={index} xs={12} sm={6} md={3}>
                    <FeatureCard {...feature} />
                  </Grid>
                ))}
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{
                mt: 8,
                mb: 8,
                bgcolor: "primary.light",
                py: 8,
                borderRadius: theme.shape.borderRadius,
              }}
            >
              <Typography
                variant="h3"
                component="h2"
                align="center"
                gutterBottom
              >
                Curated Soundtracks for Pregnancy
              </Typography>
              <Typography
                variant="h6"
                align="center"
                color="text.secondary"
                paragraph
              >
                Discover our specially designed audio tracks to support your
                pregnancy journey
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Music
                        size={48}
                        style={{
                          color: theme.palette.primary.main,
                          margin: "0 auto",
                          display: "block",
                        }}
                      />
                      <Typography
                        variant="h6"
                        component="h3"
                        align="center"
                        sx={{ mt: 2 }}
                      >
                        Relaxation Melodies
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        Soothing sounds to help you unwind and de-stress
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Music
                        size={48}
                        style={{
                          color: theme.palette.secondary.main,
                          margin: "0 auto",
                          display: "block",
                        }}
                      />
                      <Typography
                        variant="h6"
                        component="h3"
                        align="center"
                        sx={{ mt: 2 }}
                      >
                        Sleep Soundscapes
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        Gentle audio to guide you into a restful sleep
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Music
                        size={48}
                        style={{
                          color: theme.palette.primary.main,
                          margin: "0 auto",
                          display: "block",
                        }}
                      />
                      <Typography
                        variant="h6"
                        component="h3"
                        align="center"
                        sx={{ mt: 2 }}
                      >
                        Hypnobirthing Tracks
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                      >
                        Specialized audio for a calm and confident birthing
                        experience
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>

            <Container
              maxWidth="lg"
              sx={{
                mt: 12,
                mb: 8,
                bgcolor: "background.paper",
                borderRadius: theme.shape.borderRadius,
                boxShadow: 1,
                overflow: "hidden",
              }}
            >
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6} sx={{ p: 4 }}>
                  <Typography variant="h3" component="h2" gutterBottom>
                    Interactive Exercise Guidance
                  </Typography>
                  <Typography variant="h6" color="text.secondary" paragraph>
                    Stay active safely during your pregnancy journey
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    {[
                      "Improve flexibility and strength for easier labor",
                      "Reduce pregnancy discomfort and back pain",
                      "Boost energy levels and mood",
                      "Maintain healthy weight gain",
                      "Enhanced posture and balance",
                    ].map((benefit, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                          color: "text.secondary",
                        }}
                      >
                        <Box
                          component="span"
                          sx={{
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            bgcolor: "primary.main",
                            mr: 2,
                          }}
                        />
                        <Typography variant="body1">{benefit}</Typography>
                      </Box>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleJoinWaitlist}
                    sx={{ mt: 2 }}
                  >
                    Join Waitlist to Learn More
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    position: "relative",
                    minHeight: { xs: 300, md: 400 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      bgcolor: "primary.light",
                      borderRadius: theme.shape.borderRadius,
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Placeholder for 3D model - replace src with your actual model image */}
                    <Box
                      component="img"
                      src="/exercise-model-placeholder.png"
                      alt="3D Pregnancy Exercise Model"
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        position: "absolute",
                        bottom: 8,
                        right: 8,
                        bgcolor: "rgba(255, 255, 255, 0.8)",
                        px: 1,
                        borderRadius: 1,
                      }}
                    >
                      3D Model Preview
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>

            <Box id="waitlist" sx={{ bgcolor: "primary.light", py: 8 }}>
              <Container maxWidth="md" sx={{ textAlign: "center" }}>
                <Typography variant="h3" component="h2" gutterBottom>
                  Be the First to Know
                </Typography>
                <Typography variant="h6" color="text.secondary" paragraph>
                  Goodnewzz is coming soon! Join our waitlist to get early
                  access and exclusive updates.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<EmailIcon />}
                  onClick={handleJoinWaitlist}
                >
                  Join the Waitlist
                </Button>
              </Container>
            </Box>

            <Container maxWidth="md" sx={{ mt: 8, mb: 8, textAlign: "center" }}>
              <Typography variant="h3" component="h2" gutterBottom>
                Coming Soon to Your Favorite App Store
              </Typography>
              <Typography variant="h6" color="text.secondary" paragraph>
                Goodnewzz will be available for download soon. Join our waitlist
                to be notified when we launch!
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<AppleIcon />}
                    sx={{
                      bgcolor: "black",
                      "&:hover": {
                        bgcolor: "#333",
                      },
                    }}
                    onClick={handleJoinWaitlist}
                  >
                    Coming to App Store
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<AndroidIcon />}
                    sx={{
                      bgcolor: "#689f38",
                      "&:hover": {
                        bgcolor: "#558b2f",
                      },
                    }}
                    onClick={handleJoinWaitlist}
                  >
                    Coming to Google Play
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </section>
        </article>
      </main>

      <Container
        component="footer"
        maxWidth={false}
        sx={{ bgcolor: "primary.main", color: "primary.contrastText", py: 8 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" component="div">
                Goodnewzz
              </Typography>
              <Typography variant="body2">
                Empowering expectant mothers with AI-powered insights and
                personalized care.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#">
                  Home
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#features">
                  Features
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="#waitlist">
                  Join Waitlist
                </Button>
              </Typography>
              <Typography variant="body2">
                <Button color="inherit" href="/privacy-policy">
                  Privacy Policy
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Email: support@goodnewzz.app
              </Typography>
              <Typography variant="body2">Phone: +919870164615</Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 4 }}>
            © 2024 Goodnewzz. All rights reserved.
          </Typography>
        </Container>
      </Container>
    </>
  );
};

export default LandingPage;
